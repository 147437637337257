<!--
 * @Author: zxy
 * @Date: 2022-01-01 19:42:03
 * @LastEditTime: 2022-01-04 21:58:22
 * @FilePath: /sku-bill-system/src/App.vue
-->
<template>
  <router-view/>
</template>

<style lang="scss">
@import './assets/css/common.scss';
@import './assets/css/icon/iconfont.css';

body {
  margin: 0;
  padding: 0;
}
</style>
