/*
 * @Author: zxy
 * @Date: 2022-01-01 20:06:00
 * @LastEditTime: 2022-01-06 11:56:25
 * @FilePath: /sku-bill-system/src/config/index.js
 */
const EnvConfig = {
  pord: {
    // baseAPI: 'http://35.75.242.25:8088',
    baseAPI: 'https://billserve.ja.zouxinyu.club/',
    mockAPI: ''
  }
}

export default {
  EnvConfig,
  namespace: 'skuBill'
}